<template>
  <div>
    <div v-if="confluenceEditMode" class="row">
      <div class="toast-container position-fixed">
        <div
          id="liveToast"
          class="toast"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div class="toast-body bg-warning">
            <span class="fw-bold">Ссылка скопирована в буфер.</span>
          </div>
        </div>
      </div>

      <div>
        <button
          v-if="!edit && isArticle"
          class="btn btn-light h4"
          @click="showHistory()"
        >
          История версий
        </button>
        <ConfluenceHistory
          v-if="historyVisible"
          :history="history"
          :articleId="id"
        />
      </div>

      <div id="article-status">
        <div v-if="isArticle && isCurrent && !isDraft">
          <span class="badge bg-success fs-3 fw-bold mb-1 me-1">Текущая</span>
          <span class=""> {{ draftAuthor }} - {{ draftDateEdit }} </span>
        </div>
        <div v-if="isArticle && isDraft">
          <span class="badge bg-secondary fs-3 fw-bold mb-1 me-1"
            >Черновик</span
          >
          <span class=""> {{ draftAuthor }} - {{ draftDateEdit }} </span>
        </div>

        <div v-if="isArticle && isChecked && !isCurrent && !isDraft">
          <span class="badge bg-primary fs-3 fw-bold mb-1 me-1">Архив</span>
          <span class=""> {{ draftAuthor }} - {{ draftDateEdit }} </span>
        </div>
      </div>

      <div v-if="isArticle" class="btn-group">
        <div class="form-check form-switch mb-2 ms-1">
          <label for="isPrivate" class="form-label mx-2"
            >Для внутреннего пользования</label
          >
          <input
            id="isPrivate"
            style="width: 3.5rem; height: 1.5rem"
            class="form-check-input"
            type="checkbox"
            v-model="isPrivate"
            :disabled="!edit || !isModerator"
          />
        </div>
      </div>

      <div class="btn-group">
        <button
          v-if="
            confluenceCurrentCategory._id &&
            !confluenceCurrentCategory.isArticle &&
            !isArticle
          "
          @click="addArticle()"
          class="btn btn-warning"
        >
          Добавить статью
        </button>

        <button
          v-if="isArticle && !edit"
          class="btn btn-warning"
          @click="editArticle(true)"
          :disabled="isChecked && !isCurrent"
        >
          Изменить
        </button>
        <button
          v-if="isArticle && edit"
          class="btn btn-info"
          @click="editArticle(false)"
        >
          Просмотр
        </button>

        <button
          v-if="isArticle && edit"
          class="btn btn-primary"
          @click="saveArticle()"
        >
          Сохранить
          <Spinner v-if="saveArticleSpinner" />
        </button>

        <button
          v-if="isArticle && edit"
          class="btn btn-success"
          @click="sendArticleToCheck()"
          :disabled="(isChecked && !isCurrent) || checkRequestDraft"
        >
          <span v-if="!checkRequestDraft">На проверку</span>
          <span v-if="checkRequestDraft">Проверяется</span>
        </button>

        <button
          v-if="isArticle && edit && checkRequestDraft && isModerator"
          class="btn btn-success"
          @click="acceptArticleCheck()"
        >
          <span>Подтвердить</span>
        </button>

        <input
          type="file"
          id="inputFile"
          :style="{ display: 'none' }"
          name="filedata"
          @change="handleFileUpload($event)"
        />
        <button
          v-if="isArticle && edit"
          class="btn btn-light"
          @click="uploadFile"
        >
          Загрузить файл
        </button>
      </div>

      <input
        v-if="isArticle"
        v-model="label"
        type="text"
        class="form-control mt-3 fw-bold fs-3"
        placeholder="Наименование статьи"
        :disabled="!edit"
      />
    </div>

    <div v-if="isArticle" class="row">
      <v-md-editor v-model="text" :height="height" :mode="mode"></v-md-editor>
      <p class="text-muted mt-3 mx-3">Просмотров: {{ visits }}</p>

      <div
        class="btn-toolbar mb-3"
        role="toolbar"
        aria-label="Toolbar with button groups"
      >
        <div class="btn-group me-2" role="group" aria-label="First group">
          <button type="button" class="btn border-0" @click="vote(1)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="2.5em"
              viewBox="0 0 512 512"
              class="like-img"
              :class="{ blink: currentRating == 1 }"
            >
              <path
                d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"
              />
            </svg>
          </button>
          <input
            type="number"
            :value="rating"
            disabled
            class="form-control rating-value text-center"
          />
          <button type="button" class="btn border-0" @click="vote(-1)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="2.5em"
              viewBox="0 0 512 512"
              class="dislike-img"
              :class="{ blink: currentRating == -1 }"
            >
              <path
                d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div v-if="!isArticle">
      <div v-if="!(userProfile.isAdminCs || userProfile.isStaffCs)">
        <h4>
          База знаний ПРОФКЕЙС — список тем, которые описывают выполнение
          хозяйственных операций. Каждая из них раскрыта в виде инструкции со
          скриншотами и аннотациями.
        </h4>
      </div>

      <div v-if="userProfile.isAdminCs || userProfile.isStaffCs">
        <h4>
          Личный кабинет ПРОФКЕЙС (далее ЛК ПРОФКЕЙС) предназначен не только для
          клиентов компании, но и для ее сотрудников. Для сотрудников в ЛК
          ПРОФКЕЙС располагается База знаний, в которой содержится информация о
          компании, ее сотрудниках, документация и инструкции.
        </h4> <br/>
        <h4>
          Часть материалов Базы знаний предназначены только для внутреннего
          пользования, то есть доступны только сотрудникам компании ПРОФКЕЙС, а
          другая часть инструкций доступна любому пользователю.
        </h4>
      </div>

      <!-- <div class="input-group mb-3">
        <span class="input-group-text" id="basic-addon1">
          <font-awesome-icon icon="search"
        /></span>
        <input
          type="text"
          class="form-control"
          placeholder="Поиск"
          aria-label="Поиск"
          aria-describedby="basic-addon1"
          v-model="searchText"
          @keyup.enter="searchConfluenceArticle"
        />
        <button class="btn" @click="searchConfluenceArticle">Найти</button>
      </div> -->

      <Spinner v-if="searchArticleSpinner" />
      <!-- <div v-for="article in articlesFilted" :key="article._id">
        <div>
          <h3>
            <router-link
              :to="{ name: 'confluence', params: { articleId: article._id } }"
            >
              {{ article.label }}
            </router-link>
          </h3>
          <p>
            <v-md-editor
              v-model="article.fullSearchText"
              mode="preview"
            ></v-md-editor>

            <span v-if="false"
              >{{ article.prefixText }}{{ article.searchText
              }}{{ article.suffixText }}
            </span>
          </p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, nextTick } from "vue";
import useConfluenceMixin from "@/components/confluenceMixin.js";
import useCommonMixin from "@/components/commonMixin.js";
import Spinner from "@/components/Spinner.vue";
import ConfluenceHistory from "@/components/ConfluenceHistory.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import * as bootstrap from "bootstrap";
import mediumZoom from "medium-zoom";

export default {
  name: "ConfluenceArticle",
  components: { Spinner, ConfluenceHistory },
  props: { versionId: String },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const text = ref("# Hello World!");
    const label = ref("");
    const parent = ref(null);
    const id = ref("__new__");
    const isArticle = ref(false);
    const history = ref([]);
    const isPrivate = ref(false);
    const visits = ref(0);
    const rating = ref(0);
    const currentRating = ref(0);

    const searchText = ref("");
    const articlesFilted = ref([]);
    const searchArticleSpinner = ref(false);
    const saveArticleSpinner = ref(false);
    const edit = ref(false);
    const isDraft = ref(false);
    const draftAuthor = ref("");
    const draftDateEdit = ref("");
    const checkRequestDraft = ref(false);

    const historyVisible = ref(false);

    const {
      confluenceEditMode,
      confluenceCurrentCategory,
      onOpenArticle,
      postRatingArticle,
    } = useConfluenceMixin();

    const { dateFormat, scrollToId, isModerator, userProfile } =
      useCommonMixin();

    const saveArticle = async (checkRequest = false, acceptCheck = false) => {
      console.log("saveArticle");
      saveArticleSpinner.value = true;
      let article = {
        _id: id.value,
        label: label.value,
        text: text.value,
        isArticle: true,
        parent: parent.value,
        isPrivate: isPrivate.value,
        visits: 0,
      };
      article.checkRequest = checkRequest;
      article.acceptCheck = acceptCheck;

      let postCategoryResponse = await store.dispatch(
        "POSTconfluenceCategory",
        article
      );
      if (postCategoryResponse.status) {
        id.value = postCategoryResponse.data._id;
        saveArticleSpinner.value = false;
        if (checkRequest) checkRequestDraft.value = true;
        if (acceptCheck) {
          edit.value = false;
          isDraft.value = false;
          await updateArticle();
          router.push({ name: "confluence", params: { articleId: id.value } });
        }
        await store.dispatch("FETCHconfluenceCategories");
      }
    };

    const sendArticleToCheck = async () => {
      await saveArticle(true);
    };

    const acceptArticleCheck = async () => {
      await saveArticle(false, true);
    };

    const addArticle = () => {
      id.value = "__new__";
      label.value = "";
      text.value = "";
      isArticle.value = true;
      parent.value = confluenceCurrentCategory.value._id;

      editArticle(true);
    };

    const uploadFile = async () => {
      document.getElementById("inputFile").click();
    };

    const handleFileUpload = async (event) => {
      let file = event.target.files[0];
      if (file) {
        // console.log("handleFileUpload", file);
        let formData = new FormData();
        formData.append("filedata", file);

        let uploadFileResponse = await store.dispatch(
          "POSTconfluenceFile",
          formData
        );
        // console.log("uploadFileResponse", uploadFileResponse);
        if (uploadFileResponse.status) {
          let link = uploadFileResponse.data;
          // console.log(link);
          navigator.clipboard.writeText(link);
          showMsg();
        }
      }
    };

    const showMsg = () => {
      var toastLiveExample = document.getElementById("liveToast");
      var toast = new bootstrap.Toast(toastLiveExample);
      toast.show();
    };

    const mode = computed(() => {
      let result = "preview";
      if (confluenceEditMode.value && edit.value) result = "editable";
      return result;
    });

    const editArticle = async (value) => {
      edit.value = value;
      if (!value) {
        await updateArticle();
      }

      if (value || isDraft.value) {
        // console.log("editArticle - true", history.value);
        let draftIndex = history.value.findLastIndex(
          (item) => !item.dateChecked
        );
        if (draftIndex >= 0) {
          text.value = history.value[draftIndex].text;
          draftAuthor.value = history.value[draftIndex].authorName;
          draftDateEdit.value = dateFormat(history.value[draftIndex].dateEdit);
        }
        isDraft.value = true;
      }

      if (value) {
        historyVisible.value = false;
      }
    };

    const updateArticle = async () => {
      console.log("updateArticle");
      let articleResp = await store.dispatch(
        "FETCHconfluenceArticle",
        id.value
      );
      if (articleResp.status) {
        await store.commit("SETConfluenceCurrentCategory", articleResp.data);
      }
      historyVisible.value = false;
    };

    const isCurrent = computed(() => {
      let result = false;
      let currentIndex = history.value.findLastIndex(
        (item) => item.dateChecked
      );
      if (currentIndex >= 0) {
        result =
          history.value[currentIndex]._id == props.versionId ||
          !props.versionId;
      }
      return result;
    });

    const isChecked = computed(() => {
      let checkedIndex = history.value.findIndex(
        (item) => item.dateChecked && item._id == props.versionId
      );
      return checkedIndex >= 0;
    });

    const height = computed(() => {
      return confluenceEditMode.value ? "600px" : "";
    });

    const searchConfluenceArticle = async () => {
      if (searchText.value.length < 2) return;
      searchArticleSpinner.value = true;
      const response = await store.dispatch(
        "SEARCHconfluenceArticle",
        searchText.value
      );

      if (response.status) {
        articlesFilted.value = response.data;
      }
      searchArticleSpinner.value = false;
    };

    const showHistory = () => {
      historyVisible.value = historyVisible.value ? false : true;
    };

    const vote = async (ratingValue) => {
      let result = await postRatingArticle(id.value, ratingValue);
      if (result.status) {
        currentRating.value = ratingValue;
        rating.value = result.data.rating;
      }
    };

    watch(confluenceCurrentCategory, () => {
      // console.log("confluenceCurrentCategory", confluenceCurrentCategory);

      isArticle.value = confluenceCurrentCategory.value.isArticle;
      label.value = "";

      if (!confluenceCurrentCategory.value.isArticle) {
        parent.value = confluenceCurrentCategory.value._id;
      }

      if (confluenceCurrentCategory.value.isArticle) {
        // console.log(id.value, confluenceCurrentCategory.value._id);
        if (id.value != confluenceCurrentCategory.value._id) {
          isDraft.value = false;
          draftAuthor.value = "";
          draftDateEdit.value = "";
          historyVisible.value = false;
          onOpenArticle(confluenceCurrentCategory.value._id);
        }

        parent.value = confluenceCurrentCategory.value.parent;
        id.value = confluenceCurrentCategory.value._id;
        label.value = confluenceCurrentCategory.value.label;
        checkRequestDraft.value = confluenceCurrentCategory.value.checkRequest;
        isPrivate.value = confluenceCurrentCategory.value.isPrivate;
        visits.value = confluenceCurrentCategory.value.visits;
        rating.value = confluenceCurrentCategory.value.rating;

        text.value = confluenceCurrentCategory.value.text;

        history.value =
          "history" in confluenceCurrentCategory.value
            ? confluenceCurrentCategory.value.history
            : [];

        if (!props.versionId) {
          draftAuthor.value = confluenceCurrentCategory.value.authorName;
          draftDateEdit.value = dateFormat(
            confluenceCurrentCategory.value.dateEdit
          );
        }

        versionIdOnChange();
      }
      makeZoomImgs();

      searchText.value = "";
      articlesFilted.value = [];
      historyVisible.value = false;
    });

    watch(
      () => props.versionId,
      () => {
        // console.log("watch versionId", props.versionId);
        versionIdOnChange();
      }
    );

    const versionIdOnChange = () => {
      // console.log("versionIdOnChange", props.versionId);
      if (props.versionId) {
        // console.log("history.value", history.value);
        let versionIndex = history.value.findIndex(
          (item) => item._id == props.versionId
        );
        // console.log('versionIndex', versionIndex);
        if (versionIndex >= 0) {
          // console.log('versionIndex', versionIndex);
          text.value = history.value[versionIndex].text;
          isDraft.value = !history.value[versionIndex].dateChecked;
          draftAuthor.value = history.value[versionIndex].authorName;
          draftDateEdit.value = dateFormat(
            history.value[versionIndex].dateEdit
          );

          historyVisible.value = false;
          scrollToId("article-status");
        }
      }
    };

    const makeZoomImgs = () => {
      nextTick(() => {
        //let images = document.getElementsByTagName("img");
        mediumZoom(".v-md-editor__preview-wrapper img", {
          background: "var(--vp-c-bg)",
        });
        //console.log("makeZoomImgs", images);
      });
    };

    onMounted(() => {
      makeZoomImgs();
    });

    return {
      text,
      mode,
      confluenceEditMode,
      confluenceCurrentCategory,
      label,
      saveArticle,
      addArticle,
      isArticle,
      uploadFile,
      handleFileUpload,
      height,
      makeZoomImgs,
      searchText,
      articlesFilted,
      searchConfluenceArticle,
      searchArticleSpinner,
      saveArticleSpinner,
      edit,
      editArticle,
      isDraft,
      draftAuthor,
      draftDateEdit,
      showHistory,
      historyVisible,
      history,
      id,
      isCurrent,
      isChecked,
      sendArticleToCheck,
      checkRequestDraft,
      acceptArticleCheck,
      isModerator,
      isPrivate,
      visits,
      postRatingArticle,
      rating,
      vote,
      currentRating,
      userProfile,
    };
  },
};
</script>

<style>
.like-img {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  fill: #144eb3;
}

.dislike-img {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  fill: #b9c3d5;
}

.rating-value {
  max-width: 90px;
}

.blink {
  animation-name: blinker;
  animation-iteration-count: 3;
  animation-timing-function: cubic-bezier(1, 0, 0, 1);
  animation-duration: 1s;
  -webkit-animation-name: blinker;
  -webkit-animation-iteration-count: 3;
  -webkit-animation-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-animation-duration: 1s;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}
</style>
