<template>
  <div>
    <div v-if="!confluenceEditMode" class="btn-group w-100">
      <button
        type="button"
        class="list-group-item list-group-item-action"
        :class="{
          'active-category':
            confluenceCurrentCategory._id == category._id &&
            category._id != '__root__',
          'confluence-article-list': category.isArticle,
          'confluence-category-list': !category.isArticle,
          'confluence-main-category': category.isMain,
        }"
        aria-current="true"
        :style="styleObj"
        data-bs-toggle="collapse"
        :data-bs-target="`#category-collapse-${category._id}`"
        :aria-expanded="!category.parent"
        :aria-controls="`category-collapse-${category._id}`"
        @click="selectCategory()"
        ref="btnCategory"
      >
        {{ category.label }}
        <span
          v-if="category.children.length > 0"
          class="text-right"
          style="float: right; max-width: 20px"
        >
          <font-awesome-icon v-if="isExpanded" icon="chevron-down" />
          <font-awesome-icon v-if="!isExpanded" icon="chevron-up" />
        </span>
      </button>
    </div>

    <div v-if="confluenceEditMode" class="btn-group w-100">
      <input
        class="list-group-item list-group-item-action"
        :class="{
          'active-category':
            confluenceCurrentCategory._id == category._id &&
            category._id != '__root__',
          'confluence-article-list': category.isArticle,
          'confluence-category-list': !category.isArticle,
          'input-danger': !category.label,
        }"
        aria-current="true"
        :style="styleObj"
        data-bs-toggle="collapse"
        :data-bs-target="`#category-collapse-${category._id}`"
        aria-expanded="false"
        :aria-controls="`category-collapse-${category._id}`"
        @click="selectCategory()"
        ref="btnCategory"
        type="text"
        :value="category.label"
        @input="editCategory($event, category)"
      />

      <button
        v-if="isModerator"
        class="btn btn-light"
        data-bs-toggle="tooltip" data-bs-placement="top" title="Удалить"
        @click="removeCategory(category)"
      >
        <font-awesome-icon icon="trash" />
      </button>

      <button
        v-if="!category.isArticle && isModerator"
        class="btn btn-light"
        data-bs-toggle="tooltip" data-bs-placement="top" title="Добавить раздел"
        @click="addChildren(category)"
      >
        <font-awesome-icon icon="plus" />
      </button>

      <button
        v-if="!category.isArticle && isModerator"
        class="btn btn-light"
        data-bs-toggle="tooltip" data-bs-placement="top" title="Публичный/Не публичный"
        @click="showHide(category)"
      >
        <svg
          v-if="!category.isPrivate"
          xmlns="http://www.w3.org/2000/svg"
          height="16"
          width="18"
          viewBox="0 0 576 512"
        >
          <path
            d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
          />
        </svg>

        <svg
          v-if="category.isPrivate"
          xmlns="http://www.w3.org/2000/svg"
          height="16"
          width="20"
          viewBox="0 0 640 512"
        >
          <path
            d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"
          />
        </svg>
      </button>

      <button
        v-if="!category.isArticle && isModerator"
        class="btn btn-light"
        data-bs-toggle="tooltip" data-bs-placement="top" title="Основной/Не основной"
        @click="setIsMain(category)"
      >
        <svg
          v-if="category.isMain"
          xmlns="http://www.w3.org/2000/svg"
          height="16"
          width="18"
          viewBox="0 0 576 512"
        >
          <path
            d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
          />
        </svg>

        <svg
          v-if="!category.isMain"
          xmlns="http://www.w3.org/2000/svg"
          height="16"
          width="18"
          viewBox="0 0 576 512"
        >
          <path
            d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"
          />
        </svg>
      </button>

      <button
        v-if="category.edit && isModerator"
        class="btn btn-light"
        @click="saveCategory(category)"
      >
        <font-awesome-icon icon="save" />
      </button>
    </div>

    <div
      :id="`category-collapse-${category._id}`"
      ref="collapseCat"
      class="collapse"
      :class="{ show: false }"
    >
      <confluence-category-item
        v-for="(item, index) in category.children"
        :key="index"
        :category="item"
        :offset="offset + 10"
      />
    </div>
  </div>
</template>

<script>
import useConfluenceMixin from "@/components/confluenceMixin.js";
import useCommonMixin from "@/components/commonMixin.js";
import { useStore } from "vuex";
import { ref, computed } from "vue";
//import * as bootstrap from 'bootstrap'
import { useRouter } from "vue-router";

export default {
  name: "ConfluenceCategoryItem",
  props: {
    category: Object,
    offset: Number,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { confluenceCurrentCategory, confluenceEditMode } =
      useConfluenceMixin();
    const { isModerator } = useCommonMixin();

    const isExpanded = ref(true);
    const btnCategory = ref(null);
    const collapseCat = ref(null);

    const msgError = ref("");
    const selectCategory = () => {
      store.commit("SETConfluenceCurrentCategory", props.category);
      checkIfExpanded();
      router.push({
        name: "confluence",
        params: { articleId: props.category._id },
      });
    };

    const checkIfExpanded = () => {
      if (btnCategory.value) {
        let index = JSON.stringify(btnCategory.value.classList).indexOf(
          "collapsed"
        );
        isExpanded.value = index < 0;
      }
    };

    const styleObj = computed(() => {
      let padding = {};
      if (props.offset) {
        padding = { paddingLeft: `${props.offset}px` };
      }
      return padding;
    });

    const editCategory = (event, category) => {
      //console.log(category.label, event.target.value);
      // console.log(event);
      let categoryClone = JSON.parse(JSON.stringify(category));
      categoryClone.label = event.target.value;
      categoryClone.edit = true;
      let fields = { label: true, edit: true };
      //console.log(categoryClone);
      store.commit("SETConfluenceCategory", {
        category: categoryClone,
        fields: fields,
      });
    };

    // const addChildren1 = (category) => {
    //   let categoryClone = JSON.parse(JSON.stringify(props.category));
    //   let newId = Math.floor(Math.random() * 10000000);
    //   let newChildren = {
    //     _id: `__new__${newId}`,
    //     label: "",
    //     children: [],
    //     isArticle: false,
    //     edit: true,
    //     parent: category._id,
    //   };
    //   categoryClone.children.push(newChildren);
    //   let fields = { children: true };
    //   store.commit("SETConfluenceCategory", {category: categoryClone, fields: fields});
    // };

    const addChildren = async (category) => {
      let newId = Math.floor(Math.random() * 10000000);
      let newChildren = {
        _id: `__new__${newId}`,
        label: "",
        children: [],
        isArticle: false,
        edit: true,
        parent: category._id,
      };
      await store.dispatch("POSTconfluenceCategory", newChildren);
      await store.dispatch("FETCHconfluenceCategories");
    };

    const showHide = (category) => {
      category.isPrivate = category.isPrivate ? false : true;
      category.edit = true;
    };

    const setIsMain = (category) => {
      category.isMain = category.isMain ? false : true;
      category.edit = true;
    };

    const saveCategory = async (category) => {
      let response = await store.dispatch("POSTconfluenceCategory", category);
      console.log(response);
      if (response.status) {
        let categoryClone = JSON.parse(JSON.stringify(category));
        categoryClone.edit = false;
        let fields = { edit: true };
        store.commit("SETConfluenceCategory", {
          category: categoryClone,
          fields: fields,
        });
      }
    };

    const removeCategory = async (category) => {
      let categoryType = category.isArticle ? "статью" : "раздел";
      if (confirm(`Удалить ${categoryType} ${category.label}?`)) {
        let response = await store.dispatch(
          "DELETEconfluenceCategory",
          category
        );
        console.log(response);
        if (response.status) {
          await store.dispatch("FETCHconfluenceCategories");
        }
      }
    };

    // const collapseItem = () => {
    //   let collapseId = `category-collapse-${props.category._id}`;
    //   let collapseElement = new bootstrap.Collapse(
    //     document.getElementById(collapseId)
    //   );
    //   collapseElement.hide();
    // };

    return {
      selectCategory,
      confluenceCurrentCategory,
      isExpanded,
      btnCategory,
      styleObj,
      msgError,
      confluenceEditMode,
      editCategory,
      addChildren,
      saveCategory,
      removeCategory,
      collapseCat,
      isModerator,
      showHide,
      setIsMain,
    };
  },
};
</script>

<style>
.categories-container {
  max-width: 100%;
}
.active-category {
  font-weight: bold;
}

.chevron-up {
  transform: rotate(180deg);
}
.confluence-category-list {
  font-size: larger;
  color: mediumblue !important;
}

.confluence-article-list {
}

.confluence-main-category {
  background-color: #00b1ff !important;
  color: white !important;
}

.input-danger {
  border-color: red !important;
  border-width: 2px !important;
}

.text-right {
  text-align: right !important;
}
</style>
